import React from 'react';
import { TwitchPlayer } from 'react-twitch-embed';
import Sectiontitle from '../section-title';
import siteData from '../../siteData.json';

import './style.css'

const Stream = () => {
    const stream = siteData.video.stream;
    const channel = (process.env.REACT_APP_TWITCH_CHANNEL) ? process.env.REACT_APP_TWITCH_CHANNEL : stream.channel;
    const player = React.createRef()
    const unmute = () => player.current?.setMuted(false);

    return(
    <div id="stream" className="stream-section service-area section-padding">
        <div className="container">
            <Sectiontitle section={'Live Stream'}/> 
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="stream-content row justify-content-center">
                        <TwitchPlayer
                            channel={channel}
                            theme="light"
                            width="940px"
                            height="518px"
                            allowFullscreen={true}
                            autoplay={true}
                            muted={false}
                            onReady={unmute}
                            parent={['localhost','sonyanadbilly.com','billyandsonya.com','thebryants.life']}
                            className="stream-player col-10"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Stream;