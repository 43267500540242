import React from  'react';

// components
import Couple2 from '../../components/couple2';
import PreviewHero from '../../components/hero3';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import People from '../../components/people';
import Video from '../../components/video';
import Gallery from '../../components/gallery';
import Photobooth from '../../components/photobooth';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar';

const HomepageAfter = () => {
    return(
       <div>
           <Navbar/>
           <PreviewHero/>
           <Couple2/>
           <Welcome/>
           <Video/>
           <Gallery/>
           <Photobooth/>
           <Story/>
           <People/>
           <Gift/>
       </div>
    )
}

export default HomepageAfter;