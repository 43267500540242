import React, {Component} from 'react';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import Sectiontitle from '../section-title';
import PhotoGallery from 'react-photo-gallery';
import siteData from '../../siteData.json';

import './style.css'

const MailTo = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`} target='_blank' rel='noopener noreferrer nofollow'>{children}</a>;
};

class Photobooth extends Component {

  render() {
    const galleryRender = ({index, photo}) => {
      return <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={photo.key}>
          <div className="gallery-img">
            <ReactFancyBox
                defaultThumbnailWidth={parseInt('340')}
                defaultThumbnailHeight={parseInt('340')}
                //thumbnail={photo.src}
                image={photo.src}/>
          </div>
      </div>
    }
    const imageFiles = require.context(`../../images/photobooth/`, false, /\.(png|jpe?g|svg|webp)$/);

    const allImagePaths = imageFiles.keys ();
    var images = [];
    allImagePaths.map(function(image, i) {
      let photo = {
        src: imageFiles(image),
        key: "photo" + i,
        alt: "photo" + i,
        width: 400,
        height: 400,
        className: 'gallery-image'
      }
      images.push(photo);
      return images;
    });
    
    function content() { 
      const gallery = siteData.gallery;
      const intro = "Check out all of the amazing photos from our PhotoBooth!"
      const removal = "If you would like to have an image removed, please email a list of images to " 
      if (allImagePaths.length >= 1) {
        return (
          <div className="row photo-gallery">
            <div className="gallery-intro">
              <p>
                {intro}<br/>
                {removal}
                <MailTo email="billy+removeme@billyjbryant.com" subject="Remove Image from SonyaandBilly.com" body="Please remove the following images from your website:">billy+removeme@billyjbryant.com</MailTo><br/>
                <a href={gallery.photobooth} target='_blank' rel='noopener noreferrer'>Click Here to download GIFs and Photo Strips!</a>
              </p>
            </div>
            <PhotoGallery photos={images} renderImage={galleryRender} />
          </div>
        );
      } else {
        return (
          <div className="row photo-gallery no-photos">
            <div className="gallery-intro"><p>{intro}</p></div>
          </div>
        );
      }
    }

    return (
      <div id="photobooth" className="Gallery-section section-padding pb-70">
          <Sectiontitle section={'Photo Booth'}/>
          <div className="container">
                 {content()}
          </div>
      </div>
    );
  }
}

export default Photobooth;