import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import MobileMenu from '../../components/MobileMenu'
import theDay from '../../components/theday';
import siteData from '../../siteData.json'

import './style.css'

const Header = () => {

    const couple = siteData.couple
    const DAY = theDay();
    function thisContent() {
        if (DAY === 'dayof') {
            return (
                <div className="header-menu d-lg-block d-none">
                    <ul className="mobail-menu d-flex">
                        <li><AnchorLink href='#home'>Home</AnchorLink></li>
                        <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                        <li><AnchorLink href='#stream'>Watch</AnchorLink></li>
                        <li><AnchorLink href='#story'>Story</AnchorLink></li>
                        <li><AnchorLink href='#people'>Party</AnchorLink></li>
                        <li><AnchorLink href='#event'>Event</AnchorLink></li>
                        <li><AnchorLink href='#gallery'>Gallery</AnchorLink></li>
                        <li><AnchorLink href='#safety'>Safety</AnchorLink></li>
                        <li><AnchorLink href='#gift'>Registry</AnchorLink></li>
                    </ul>
                </div>
            )
        } else if (DAY === 'after') {
            return (
                <div className="header-menu d-lg-block d-none">
                    <ul className="mobail-menu d-flex">
                        <li><AnchorLink href='#home'>Home</AnchorLink></li>
                        <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                        <li><AnchorLink href='#video'>Video</AnchorLink></li>
                        <li><AnchorLink href='#gallery'>Gallery</AnchorLink></li>
                        <li><AnchorLink href='#photobooth'>Photobooth</AnchorLink></li>
                        <li><AnchorLink href='#story'>Story</AnchorLink></li>
                        <li><AnchorLink href='#people'>Party</AnchorLink></li>
                        <li><AnchorLink href='#gift'>Registry</AnchorLink></li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div className="header-menu d-lg-block d-none">
                    <ul className="mobail-menu d-flex">
                        <li><AnchorLink href='#home'>Home</AnchorLink></li>
                        <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                        <li><AnchorLink href='#story'>Story</AnchorLink></li>
                        <li><AnchorLink href='#people'>Party</AnchorLink></li>
                        <li><AnchorLink href='#event'>Event</AnchorLink></li>
                        <li><AnchorLink href='#gallery'>Gallery</AnchorLink></li>
                        <li><AnchorLink href='#safety'>Safety</AnchorLink></li>
                        <li><AnchorLink href='#gift'>Registry</AnchorLink></li>
                    </ul>
                </div>
            )
        }
    }
    return(

        <div className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-10">
                            <div className="logo">
                                <h2><Link to='/'>{couple.bride.name.first}<span><i className="fa fa-heart" aria-hidden="true"></i></span>{couple.groom.name.first}</Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            {thisContent()}
                        </div>
                        <div className="col-2">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
             </div>
      </div>
        
    )
}

export default Header;
