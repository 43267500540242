import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from '../HomePage'
import HomepageDayOf from '../HomePage-DayOf'
import HomepageAfter from '../HomePage-After'
import Redirect from '../../components/redirect'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import theDay from '../../components/theday';
import GA from '../../components/ga'
import './App.css';
import siteData from '../../siteData.json';

const App = () => { 
  const DAY = theDay();
  const HOME = (DAY === 'dayof') ? HomepageDayOf : ((DAY === 'after') ? HomepageAfter : Homepage);

  return (
    <div className="App">
       <Router>
          { GA.init() && <GA.RouteTracker /> }
          <Switch>
            <Route exact path='/' day={DAY} component={HOME} />
            <Route exact path='/hotel/bwp' render={(props) => (
                <Redirect {...props} url={siteData.travel.hotel[0].reservation} name={siteData.travel.hotel[0].name}/>
              )}
            />
            <Route exact path='/hotel/hiex' render={(props) => (
                <Redirect {...props} url={siteData.travel.hotel[1].reservation} name={siteData.travel.hotel[1].name}/>
              )}
            />
          </Switch>
          <Footer/>
          <Scrollbar/>
      </Router>
      
    </div>
  );
}

export default App;
