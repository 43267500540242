import React from 'react';
import { DateTime } from "luxon";
import Sectiontitle from '../section-title';
import siteData from '../../siteData.json';

import './style.css'

const Location = () => {
    const ceremony = siteData.wedding.ceremony;
    const reception = siteData.wedding.reception;
    const afterparty = siteData.wedding.afterparty;
    var dateFormat = {...DateTime.DATETIME_FULL, weekday: 'long', timeZone: 'America/Los_Angeles' };
    return(

        
    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'When & Where'}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={ceremony.venue.picture} alt={ceremony.venue.name}/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Wedding Ceremony</h3>
                                <span>
                                    {DateTime.fromISO(ceremony.date).toLocaleString(dateFormat)}
                                </span>
                                <span className="venue"><em>{ceremony.venue.name}</em></span>
                                <span>{ceremony.venue.address}</span>
                                <p>{ceremony.description}</p>
                                <span><a href={ceremony.venue.website} target="_blank" rel='noopener noreferrer'>See Location</a> | <a href={ceremony.venue.directions} target="_blank" rel='noopener noreferrer'>Get Directions</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="ceromony-content ceromony-content2">
                            <h3>Wedding Reception</h3>
                                <span>
                                    {DateTime.fromISO(reception.date).toLocaleString(dateFormat)}
                                </span>
                                <span className="venue"><em>{reception.venue.name}</em></span>
                                <span>{reception.venue.address}</span>
                                <p>{reception.description}</p>
                                <span><a href={reception.venue.website} target="_blank" rel='noopener noreferrer'>See Location</a> | <a href={reception.venue.directions} target="_blank" rel='noopener noreferrer'>Get Directions</a></span>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                            <img src={reception.venue.picture} alt={reception.venue.name}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img src={afterparty.venue.picture} alt={afterparty.venue.name}/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>After Party</h3>
                                <span>
                                    {DateTime.fromISO(afterparty.date).toLocaleString(dateFormat)}
                                </span>
                                <span className="venue"><em>{afterparty.venue.name}</em></span>
                                <span>{afterparty.venue.address}</span>
                                <p>{afterparty.description}</p>
                                <span><a href={afterparty.venue.website} target="_blank" rel='noopener noreferrer'>See Location</a> | <a href={afterparty.venue.directions} target="_blank" rel='noopener noreferrer'>Get Directions</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;