import React from 'react'
import { DateTime } from "luxon";
import siteData from '../../siteData.json'
import theDay from '../../components/theday';
import './style.css'


const Story = () =>{

    const ourStory = (theDay() === 'after') ? siteData.couple.timeline : siteData.couple.timeline.slice(0, -1);

    const stories = ourStory.map(function(story) {
        return <div className="item" key={story.name}>
                    <div className="story-icon">
                        <span className="flaticon-birds-in-love"></span>
                    </div>
                    <div className="story-content">
                        <div className="image-wrap">
                            <div className="single-image">
                                <div>
                                    <img src={story.picture} alt=""/>
                                </div>
                            </div>
                        </div>
                        <h2>{story.name}</h2>
                        <span className="date">
                            {DateTime.fromISO(story.date).toLocaleString(DateTime.DATE_FULL)}
                        </span>
                        <p>{story.story}</p>
                    </div>
                </div>
    })

    return(
        <div id="story" className="story-area section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title text-center">
                        <h2>Our Story</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="story clearfix">
                            <div className="story-top"></div>
                            <div className="content-wrapper">
                                {stories}
                            </div>
                            <div className="story-bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Story;