import React, {Component} from 'react';
import Sectiontitle from '../section-title';
import ReactPlayer from 'react-player/youtube'
import siteData from '../../siteData.json'
import './style.css'

class Video extends Component {

  render() {

    const video = siteData.video;
    const videoURL = (process.env.REACT_APP_WEDDING_VIDEO) ? process.env.REACT_APP_WEDDING_VIDEO : 'https://www.youtube.com/watch?v=aLCBDM-PDN0';
    const intro = (process.env.REACT_APP_WEDDING_VIDEO) ? video.uploaded : video.novideo;
    return (
      <div id="video" className="video-section section-padding pb-70">
          <Sectiontitle section={'Watch the Wedding'}/>
          <div className="container">
              <div className="row video">
                <div className="video-intro"><p>{intro}</p></div>
                <div className="player-wrapper">
                  <ReactPlayer 
                    url={videoURL}
                    className='react-player'
                    height="100%"
                    width="100%"
                    controls={true}
                    pip={true}
                    stopOnUnmount={false}
                  />
                </div>
              </div>
          </div>
      </div>
    )
  }
}

export default Video;