import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import siteData from '../../siteData.json'

import './style.css'

const People = (props) => {
    const party = siteData.wedding.party;

    const peopleRender = (type,person,i) => {
        let socialBlock = '';
        if (person.social) {
            const socials = person.social.map(function(site) {
                return <li key={site.name}><Link to={{ pathname: site.url }} target="_blank" rel="noopener nofollow"><i className={"fa fa-" + site.name}></i></Link></li>
            })
            socialBlock = <div className="social-list"><ul>{socials}</ul></div>
        }
        return <div className="col-lg-4 col-md-6 col-sm-6" key={`${type}-${person.name.first}`} >
                    <div className="person-wrap">
                        <div className="person-img">
                            <img src={person.picture} alt=""/>
                            {socialBlock}
                        </div>
                        <div className="person-content">
                            <h3>{person.name.first} {person.name.last}</h3>
                            <span>{person.title}</span>
                        </div>
                    </div>
                </div>
    }

    const family = party.family.map(function(person) {
        return peopleRender('family', person)
    })

    const ceremony = party.ceremony.map(function(person) {
        return peopleRender('ceremony', person)
    })

    const bridesmaids = party.bridesmaids.map(function(person) {
        return peopleRender('bridesmaids', person)
    })

    const groomsmen = party.groomsmen.map(function(person) {
        return peopleRender('groomsmen', person)
    })

    const [activeTab, setActiveTab] = useState('1');

        const toggle = tab => {
          if(activeTab !== tab) setActiveTab(tab);
        }
    return(

        <div id="people" className="person-area section-padding pb-70">
            <div className="container">
                <Sectiontitle section={'The Wedding Party'}/>
                <div className="person-area-menu">
                    <div className="person-btn">
                        <Nav tabs>
                            <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Groomsman
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Bridemaids
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                Officiant
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { toggle('4'); }}
                            >
                                Family
                            </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                        <div className="Groomsman-wrap">
                                <div className="row justify-content-center">
                                    {groomsmen}    
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                        <div className="Ceremony-wrap">
                                <div className="row justify-content-center">
                                    {bridesmaids}    
                                </div></div>
                        </TabPane>
                        <TabPane tabId="3">
                        <div className="Ceremony-wrap">
                                <div className="row justify-content-center">
                                    {ceremony}    
                                </div></div>
                        </TabPane>
                        <TabPane tabId="4">
                        <div className="Ceremony-wrap">
                                <div className="row justify-content-center">
                                    {family}    
                                </div></div>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
        
    )
}

export default People;