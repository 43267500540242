import React, { Component } from "react";
import Slider from "react-slick";
import theDay from "../../components/theday";
import siteData from '../../siteData.json'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

const Registry = ({ logo, url, name }) => {
  return <div id={`${name}-registry`} className="registry-container"><a href={url} name={name} id={`${name}-link`} target='_blank' rel='noopener noreferrer nofollow'><img className="registry-logo" src={logo} alt={name} id={`${name}-logo`} name={name}/></a></div>;
};

class Gift extends Component {
    render() {

      const registries = siteData.wedding.registry.stores.map(function(site) {
        return <div className="item" key={site.name}>
                    <Registry name={site.name} logo={site.logo} url={site.url}/>
               </div>
      })

      var settings = {
        dots: false,
        arrows: false,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:3500,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
              }
            }
          ]
      };

      const message = (theDay() === 'after') ? siteData.wedding.registry.after : siteData.wedding.registry.message;
      return (
          <div id="gift" className="gift-area">
              <div className="container">
                    <div className="col-12">
                      <div className="section-title text-center">
                          <h2>Gift Registration</h2>
                          <p>{message}</p>
                      </div>
                  </div>
                  <div className="row gift-item">
                      <div className="col-lg-12" style={{justifyContent: "center"}}>
                        <Slider {...settings}>
                            {registries}
                        </Slider>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
  }

export default Gift;