import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PreviewHero from '../hero3';
import './style.css'

export class Redirect extends Component {
  constructor(props) {
      super(props);

      this.state = {...props};
  }

  componentWillMount(){
    window.open(this.state.url, "_self", "noopener noreferrer");
  }
  
  render(){
    const Notice = (props) => {
      return (
        <div id="Notice">
          <Modal
            isOpen={true}
            className="redirect-modal"
            contentClassName="redirect-modal-content">
            <ModalHeader closeButton>Redirecting...</ModalHeader>
            <ModalBody>Please wait, you are being redirected to<br/><em>{props.name}</em></ModalBody>
          </Modal>
        </div>
      );
    }

    return (
        <div>
            <PreviewHero/>
            <Notice url={this.state.url} name={this.state.name}/>
        </div>
    );
  }
}

export default Redirect;