import React, {Component} from 'react';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import Sectiontitle from '../section-title';
import theDay from '../../components/theday';
import PhotoGallery from 'react-photo-gallery';
import siteData from '../../siteData.json'
//import Pagination from '../pagination';
import './style.css'

class Gallery extends Component {

  render() {
    const gallery = siteData.gallery;
    const galleryRender = ({index, photo}) => {
      return <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={photo.key}>
          <div className="gallery-img">
            <ReactFancyBox
                defaultThumbnailWidth={parseInt('340')}
                defaultThumbnailHeight={parseInt('340')}
                //thumbnail={photo.src}
                image={photo.src}/>
          </div>
      </div>
    }
    const DAY = theDay();
    const imageFiles = (DAY === 'after') ? require.context(`../../images/wedding/`, false, /\.(png|jpe?g|svg|webp)$/) : require.context(`../../images/gal/`, false, /\.(png|jpe?g|svg|webp)$/);

    const allImagePaths = imageFiles.keys ();
    var images = [];
    allImagePaths.map(function(image, i) {
      let photo = {
        src: imageFiles(image),
        key: "photo" + i,
        alt: "photo" + i,
        width: 400,
        height: 400,
        className: 'gallery-image'
      }
      images.push(photo);
      return images;
    });
    
    function content() { 
      if (allImagePaths.length >= 1) {
        const intro = (DAY === 'after') ? gallery.uploaded : '';
        return (
          <div className="row photo-gallery">
            <div className="gallery-intro"><p>{intro}</p></div>
            <PhotoGallery photos={images} renderImage={galleryRender} />
          </div>
        );
      } else {
        const intro = (DAY === 'after') ? gallery.nophoto : ''
        return (
          <div className="row photo-gallery no-photos">
            <div className="gallery-intro"><p>{intro}</p></div>
          </div>
        );
      }
    }

    return (
      <div id="gallery" className="Gallery-section section-padding pb-70">
          <Sectiontitle section={'Our Gallery'}/>
          <div className="container">
                 {content()}
          </div>
      </div>
    );
  }
}

export default Gallery;