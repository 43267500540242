import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import siteData from '../../siteData.json'
import theDay from '../../components/theday'
import './style.css'

const MailTo = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`} target='_blank' rel='noopener noreferrer nofollow'>{children}</a>;
};

const DAY = theDay();
function thisContent() {
    if (DAY === 'dayof') {
        return (
        <div className="welcome-content">
            <h2>Welcome to our Wedding</h2>
            <p>{siteData.welcome.dayof}</p>
            <div className="btn"><AnchorLink href='#stream'>Watch Live</AnchorLink></div>
            <div className="btn"><AnchorLink href='#event'>Event Details</AnchorLink></div>
            <div className="btn"><AnchorLink href='#gift'>Registry</AnchorLink></div>
        </div>
        )
    } else if (DAY === 'after') {
        return (
        <div className="welcome-content">
            <h2>Thank You for Celebrating With Us!</h2>
            <p>{siteData.welcome.after}</p>
            <div className="btn"><AnchorLink href='#video'>Video</AnchorLink></div>
            <div className="btn"><AnchorLink href='#gallery'>Gallery</AnchorLink></div>
            <div className="btn"><AnchorLink href='#gift'>Registry</AnchorLink></div>
        </div>
        )
    } else {
        return (
        <div className="welcome-content">
            <h2>Welcome to our Wedding</h2>
            <p>{siteData.welcome.before}</p>
            <div className="btn"><AnchorLink href='#event'>Event Details</AnchorLink></div>
            <div className="btn"><MailTo email={siteData.contactEmail} subject="Wedding Information" body="">Email Us</MailTo></div>
        </div>
        )
    }
}

const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {thisContent()}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;