import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link} from 'react-router-dom'
import theDay from '../../components/theday';
import siteData from '../../siteData.json'
import './style.css';

export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    render() {

        const { isMenuShow } = this.state;
        const couple = siteData.couple
        const DAY = theDay();
        function thisContent(data) {
            if (DAY === 'dayof') {
                return (
                    <ul className="responsivemenu">
                        <li><AnchorLink href='#home'>Home</AnchorLink></li>
                        <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                        <li><AnchorLink href='#stream'>Watch</AnchorLink></li>
                        <li><AnchorLink href='#story'>Story</AnchorLink></li>
                        <li><AnchorLink href='#people'>Party</AnchorLink></li>
                        <li><AnchorLink href='#event'>Event</AnchorLink></li>
                        <li><AnchorLink href='#gallery'>Gallery</AnchorLink></li>
                        <li><AnchorLink href='#safety'>Safety</AnchorLink></li>
                        <li><AnchorLink href='#gift'>Registry</AnchorLink></li>
                        <li><Link to='/' onClick={data.menuHandler}><i className="fa fa-window-close" style={{"left": "30px"}}></i><span style={{"paddingLeft": "25px"}}>Close</span></Link></li>
                    </ul>
                )
            } else if (DAY === 'after') {
                return (
                    <ul className="responsivemenu">
                        <li><AnchorLink href='#home'>Home</AnchorLink></li>
                        <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                        <li><AnchorLink href='#video'>Video</AnchorLink></li>
                        <li><AnchorLink href='#gallery'>Gallery</AnchorLink></li>
                        <li><AnchorLink href='#photobooth'>Photobooth</AnchorLink></li>
                        <li><AnchorLink href='#story'>Story</AnchorLink></li>
                        <li><AnchorLink href='#people'>Party</AnchorLink></li>
                        <li><AnchorLink href='#gift'>Registry</AnchorLink></li>
                        <li><Link to='/' onClick={data.menuHandler}><i className="fa fa-window-close" style={{"left": "30px"}}></i><span style={{"paddingLeft": "25px"}}>Close</span></Link></li>
                    </ul>
                )
            } else {
                return (
                    <ul className="responsivemenu">
                        <li><AnchorLink href='#home'>Home</AnchorLink></li>
                        <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                        <li><AnchorLink href='#story'>Story</AnchorLink></li>
                        <li><AnchorLink href='#people'>Party</AnchorLink></li>
                        <li><AnchorLink href='#event'>Event</AnchorLink></li>
                        <li><AnchorLink href='#gallery'>Gallery</AnchorLink></li>
                        <li><AnchorLink href='#safety'>Safety</AnchorLink></li>
                        <li><AnchorLink href='#gift'>Registry</AnchorLink></li>
                        <li><Link to='/' onClick={data.menuHandler}><i className="fa fa-window-close" style={{"left": "30px"}}></i><span style={{"paddingLeft": "25px"}}>Close</span></Link></li>
                    </ul>
                )
            }
        }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="logo2">
                        <h2><Link to='/'>{couple.bride.name.first}<span><i className="fa fa-heart" aria-hidden="true"></i></span>{couple.groom.name.first}</Link></h2>
                    </div>
                    {thisContent(this)}
                </div>

                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>         
            </div>
        )
    }
}
