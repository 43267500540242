import React from 'react';
import Sectiontitle from '../section-title';
import siteData from '../../siteData.json';

import './style.css'

const Safety = () => {
    const safety = siteData.travel.safety;

    const guidelines = safety.guidelines.map(function(guideline, index) {
        return <li key={`guideline-${index}`}><span className="flaticon-heart-1">&nbsp;</span>{guideline}</li>        
    });

    return(
    <div id="safety" className="safety-section service-area section-padding">
        <div className="container">
            <Sectiontitle section={'Safety'}/> 
            <div className="row">
                <div className="col-lg-12">
                    <div className="safety-content">
                        <p>
                            {safety.message}&nbsp;
                        </p>
                        <p>
                            <ul className="safety-guidelines">{guidelines}</ul>
                        </p>
                        <p>
                            {safety.statement}&nbsp;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Safety;