import { DateTime } from "luxon";
import siteData from '../../siteData.json'

export default function theDay() {
    const theDate = new DateTime.fromISO(siteData.wedding.ceremony.date);
    const today = new DateTime.local();
    if (process.env.NODE_ENV === 'development') { console.debug(process.env); }
    if (process.env.REACT_APP_DAY) { console.debug(`The site is being overridden to ${process.env.REACT_APP_DAY} the wedding!`); return process.env.REACT_APP_DAY; }

    if (today.toMillis() >= theDate.plus({ hours: 1 }).toMillis()) {
        return 'after'
    } else if (today.startOf('day').toMillis() === theDate.startOf('day').toMillis() ) {
        return 'dayof'
    } else {
        return 'before'
    }
}