import React from 'react'
import {Link} from 'react-router-dom'
import theDay from '../../components/theday';
import './style.css'
import siteData from '../../siteData.json'

const Couple2 = () => {
    const couple = siteData.couple;
    const bride = couple.bride;
    bride.Social = bride.social.map(function(site) {
        return <li key={site.name}><Link to={{ pathname: site.url }} target="_blank"><i className={"fa fa-" + site.name}></i></Link></li>
    })
    const groom = couple.groom;
    groom.Social = groom.social.map(function(site) {
        return <li key={site.name}><Link to={{ pathname: site.url }} target="_blank"><i className={"fa fa-" + site.name}></i></Link></li>
    })
    bride.name.last = (theDay() === 'after') ? groom.name.last : bride.name.last; 
    const verb = (theDay() === 'after') ? 'Newlyweds' : 'Happy Couple';
    return(
        <div id="couple" className="couple-area section-padding pb-70">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title text-center">
                        <h2>The {verb}</h2>
                    </div>
                </div>
                <div className="couple-wrap">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 couple-single">
                            <div className="couple-wrap2">
                                <div className="couple-img3">
                                    <img src={bride.picture} alt=""/>
                                </div>
                                <div className="couple-text">
                                    <div className="couple-content">
                                        <h4>{bride.name.first} {bride.name.last}</h4>
                                        <p>{bride.about}</p>
                                    </div>
                                    <div className="social-icon">
                                        <ul className="d-flex">
                                            {bride.Social}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 couple-single">
                            <div className="couple-img2">
                                <img src={couple.picture} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 couple-single md-0">
                            <div className="couple-wrap2">
                                <div className="couple-img3">
                                    <img src={groom.picture} alt=""/>
                                </div>
                                <div className="couple-text">
                                    <div className="couple-content">
                                        <h4>{groom.name.first} {groom.name.last}</h4>
                                        <p>{groom.about}</p>
                                    </div>
                                    <div className="social-icon">
                                        <ul className="d-flex">
                                            {groom.Social}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                
    )
}

export default Couple2;